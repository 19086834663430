import { Client, Account, Storage, Databases, ID } from 'appwrite';

const client = new Client()
    .setEndpoint('https://appwrite.app.wenz.tech/v1') // Appwrite Cloud Endpoint
    .setProject('669aead000139a997afc'); // Your project ID


const account = new Account(client);
const storage = new Storage(client);
const databases = new Databases(client);


export { client, account, storage, databases, ID };
