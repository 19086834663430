// src/utils/colors.js
export const colors = {
    background: '#FFFAFF',
    text: '#1f2937',
    mutedText: '#6B7280',
    accent: '#D8315B',
    lightGrey: '#E5E5E5',
    veryLightGrey: '#F3F4F6',
    highlightGrey: '#D0D0D0',
  };
