import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Menu, X, Play, Pause, Trash2 } from 'lucide-react';
import { colors } from '../utils/colors';
import Navigation from './Navigation';
import { account, storage, client } from '../utils/appwriteClient';


const DashboardScreen = ({ onNavigate, session }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [recordings, setRecordings] = useState({});
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioElement, setAudioElement] = useState(null);

  const fetchRecordingsData = useCallback(async () => {
    if (!session || !session.$id) {
      console.error('No valid session');
      setError('No valid session. Please log in again.');
      return;
    }

    try {
      // Check if the session is still valid
      const currentSession = await account.getSession('current');
      if (!currentSession) {
        console.error('Session is no longer valid');
        setError('Your session has expired. Please log in again.');
        return;
      }

      const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
      const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

      const files = await storage.listFiles('669aeb020037a5acddf2');
      console.log('Files:', files.files);

      const monthRecordings = {};
      files.files.forEach(file => {
      const match = file.name.match(/^([^_]+)_recording_(\d{2})\.(\d{2})\.(\d{4})_(\d{2}):(\d{2})\.webm$/);
        if (match) {
          const [, userId, day, month, year, hours, minutes] = match;
          const date = new Date(year, parseInt(month) - 1, day);
          if (date >= startOfMonth && date <= endOfMonth && userId === session.$id) {
            const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            if (!monthRecordings[formattedDate]) {
              monthRecordings[formattedDate] = [];
            }
            monthRecordings[formattedDate].push({
              fileName: file.name,
              time: `${hours}:${minutes}`,
              fileId: file.$id,
              userId: userId
            });
          }
        }
      });

      Object.values(monthRecordings).forEach(recordings => {
        recordings.sort((a, b) => a.time.localeCompare(b.time));
      });

      setRecordings(monthRecordings);
    } catch (error) {
      console.error('Error fetching recordings:', error);
      setError('Failed to fetch recordings. Please try again.');
    }
  }, [currentMonth, session]);

  useEffect(() => {
    fetchRecordingsData();
  }, [fetchRecordingsData]);
  
  const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = (new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay() + 6) % 7; // Adjust for Monday start

  const prevMonth = useCallback(() => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  }, [currentMonth]);

  const nextMonth = useCallback(() => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  }, [currentMonth]);

  useEffect(() => {
    if (session) {
      fetchRecordingsData();
    }
  }, [session, currentMonth, fetchRecordingsData]);

  const handleDayClick = (date) => {
    if (recordings[date] && recordings[date].length > 0) {
      setSelectedDate(date);
      setSelectedRecording(recordings[date][0]);
      setIsModalOpen(true);
      setIsPlaying(false);
      if (audioElement) {
        audioElement.pause();
        setAudioElement(null);
      }
    } else {
      console.log('No recordings for this date');
    }
  };

const handlePlayPause = async () => {
  console.log('handlePlayPause called');

  if (!selectedRecording) {
    console.log('No recording selected');
    setError('Please select a recording first');
    return;
  }

  try {
    if (!audioElement || audioElement.src.split('/').pop() !== selectedRecording.fileId) {
      await loadAudio();
    }

    if (isPlaying) {
      console.log('Pausing audio');
      audioElement.pause();
      setIsPlaying(false);
    } else {
      console.log('Playing audio');
      try {
        await audioElement.play();
        console.log('Audio started playing');
        setIsPlaying(true);
      } catch (playError) {
        console.error('Error playing audio:', playError);
        setError('Failed to play audio: ' + playError.message);
      }
    }
  } catch (error) {
    console.error('Error handling audio:', error);
    setError('Failed to handle audio: ' + error.message);
  }
};

const loadAudio = async () => {
  console.log('Loading audio');

  if (audioElement) {
    audioElement.pause();
  }

  try {
    const result = await storage.getFileDownload('669aeb020037a5acddf2', selectedRecording.fileId);
    console.log("File download result:", result);

    if (!(result instanceof Blob)) {
      throw new Error('Download result is not a Blob');
    }

    const audioBlob = new Blob([result], { type: 'audio/webm' });
    console.log('Audio Blob:', audioBlob);

    if (audioBlob.size === 0) {
      throw new Error('Audio blob is empty');
    }

    const audioUrl = URL.createObjectURL(audioBlob);
    console.log('Audio URL:', audioUrl);
    
    const newAudioElement = new Audio(audioUrl);

    newAudioElement.addEventListener('loadedmetadata', () => {
      console.log('Audio metadata loaded');
      logFileAnalysis(newAudioElement, audioBlob, selectedRecording.fileName);
    });

    newAudioElement.addEventListener('error', (e) => {
      console.error('Audio error:', e);
      setError('Failed to load audio: ' + (e.target.error ? e.target.error.message : 'Unknown error'));
    });

    newAudioElement.addEventListener('canplaythrough', () => {
      console.log('Audio can play through');
    });

    setAudioElement(newAudioElement);
    setIsPlaying(false);
  } catch (error) {
    console.error('Error loading audio:', error);
    setError('Failed to load audio: ' + error.message);
  }
};

const logFileAnalysis = (audioElement, audioBlob, fileName) => {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  const fileFormat = {
    'webm': 'WebM',
    'wav': 'WAVE',
    'mp3': 'MPEG Audio'
  }[fileExtension] || 'Unknown';

  const audioFormat = {
    'webm': 'Opus',
    'wav': 'PCM',
    'mp3': 'MPEG Audio'
  }[fileExtension] || 'Unknown';

  console.log('File Analysis:', {
    General: {
      Format: fileFormat,
      FileSize: audioBlob.size + ' bytes',
      WritingApplication: 'Chrome',
      WritingLibrary: 'Chrome'
    },
    Audio: {
      Format: audioFormat,
      FormatVersion: fileExtension === 'webm' ? 'Opus' : (fileExtension === 'mp3' ? 'Version 1' : ''),
      FormatProfile: fileExtension === 'mp3' ? 'Layer 3' : '',
      Channels: audioElement.mozChannels || audioElement.webkitAudioChannelCount || 'Unknown',
      SamplingRate: (audioElement.sampleRate || 'Unknown') + ' Hz',
      CompressionMode: 'Lossy',
      StreamSize: audioBlob.size + ' bytes',
      Duration: audioElement.duration ? audioElement.duration.toFixed(2) + ' seconds' : 'Unknown'
    }
  });
};


  const handleDeleteRecording = async (recording) => {
    if (window.confirm('Are you sure you want to delete this recording?')) {
      try {
        await storage.deleteFile('669aeb020037a5acddf2', recording.fileId);
        setRecordings(prevRecordings => {
          const updatedRecordings = { ...prevRecordings };
          updatedRecordings[selectedDate] = updatedRecordings[selectedDate].filter(r => r.fileId !== recording.fileId);
          if (updatedRecordings[selectedDate].length === 0) {
            delete updatedRecordings[selectedDate];
          }
          return updatedRecordings;
        });
        if (selectedRecording && selectedRecording.fileId === recording.fileId) {
          setSelectedRecording(null);
          setIsPlaying(false);
          if (audioElement) {
            audioElement.pause();
          }
        }
      } catch (error) {
        console.error('Error deleting recording:', error);
        setError('Failed to delete recording. Please try again.');
      }
    }
  };


  return (
    <div className="flex flex-col h-screen p-4 sm:p-8" style={{ backgroundColor: colors.background }}>
      <div className="flex items-center justify-between mb-4 sm:mb-6">
        <h1 className="text-xl sm:text-2xl font-bold" style={{ color: colors.text }}>Your Recordings</h1>
        <button onClick={() => setIsNavOpen(true)} className="p-2 rounded-full" style={{ backgroundColor: colors.lightGrey }}>
          <Menu size={24} color={colors.text} />
        </button>
      </div>
      
      <div className="flex justify-between items-center mb-4">
        <button onClick={prevMonth}><ChevronLeft size={20} color={colors.text} /></button>
        <h2 className="text-lg sm:text-xl font-semibold" style={{ color: colors.text }}>
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </h2>
        <button onClick={nextMonth}><ChevronRight size={20} color={colors.text} /></button>
      </div>

      <div className="grid grid-cols-7 gap-1 sm:gap-2 mb-2">
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
          <div key={day} className="text-center text-xs sm:text-sm font-medium" style={{ color: colors.text }}>{day[0]}</div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1 sm:gap-2 flex-grow">
        {[...Array(firstDayOfMonth).keys()].map(i => (
          <div key={`empty-${i}`} className="aspect-square"></div>
        ))}
        {[...Array(daysInMonth).keys()].map(day => {
          const date = `${currentMonth.getFullYear()}-${String(currentMonth.getMonth() + 1).padStart(2, '0')}-${String(day + 1).padStart(2, '0')}`;
          const hasRecording = recordings[date] && recordings[date].length > 0;
          return (
            <div 
              key={day} 
              className={`aspect-square flex flex-col items-center justify-center rounded-lg text-xs sm:text-sm ${hasRecording ? 'cursor-pointer' : 'cursor-default'}`}
              style={{ 
                backgroundColor: hasRecording ? colors.highlightGrey : colors.veryLightGrey,
                color: hasRecording ? colors.text : colors.mutedText
              }}
              onClick={() => hasRecording && handleDayClick(date)}
            >
              <span className="font-medium">{day + 1}</span>
              {hasRecording && <span className="text-xxs sm:text-xs">●</span>}
            </div>
          );
        })}
      </div>

      <Navigation 
        isOpen={isNavOpen} 
        onClose={() => setIsNavOpen(false)} 
        onNavigate={onNavigate}
        currentScreen="dashboard"
      />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="rounded-lg p-6 w-full max-w-md max-h-[80vh] overflow-y-auto" style={{ backgroundColor: colors.background }}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold" style={{ color: colors.text }}>Recordings for {selectedDate}</h3>
              <button onClick={() => setIsModalOpen(false)} style={{ color: colors.mutedText }}>
                <X size={24} />
              </button>
            </div>
            <div className="space-y-4">
              {recordings[selectedDate] && recordings[selectedDate].length > 0 ? (
                recordings[selectedDate].map((recording, index) => (
                  <div 
                    key={recording.fileId} 
                    className={`flex items-center justify-between p-2 border rounded cursor-pointer ${selectedRecording && selectedRecording.fileId === recording.fileId ? 'border-2' : ''}`} 
                    style={{ 
                      borderColor: selectedRecording && selectedRecording.fileId === recording.fileId ? colors.accent : colors.lightGrey,
                      backgroundColor: selectedRecording && selectedRecording.fileId === recording.fileId ? colors.highlightGrey : 'transparent'
                    }}
                    onClick={() => setSelectedRecording(recording)}
                  >
                    <span className="font-medium" style={{ color: colors.text }}>Recording {index + 1} ({recording.time})</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRecording(recording);
                      }}
                      className="rounded-full p-2 hover:bg-opacity-80"
                      style={{ backgroundColor: colors.accent, color: colors.background }}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ color: colors.text }}>No recordings found for this date.</p>
              )}
            </div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handlePlayPause}
                className={`rounded-full p-3 ${selectedRecording ? 'hover:bg-opacity-80' : 'opacity-50 cursor-not-allowed'}`}
                style={{ backgroundColor: colors.accent, color: colors.background }}
                disabled={!selectedRecording}
              >
                {isPlaying ? <Pause size={24} /> : <Play size={24} />}
              </button>
            </div>
            {error && <p className="mt-2 text-center" style={{ color: colors.accent }}>{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardScreen;
