import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Mic, Menu, Play, Pause, Upload, Trash2, CheckCircle } from 'lucide-react';
import { format } from 'date-fns';
import { colors } from '../utils/colors';
import Navigation from './Navigation';
import { account, storage, client } from '../utils/appwriteClient';
import { v4 as uuidv4 } from 'uuid';
import {Permission, Role} from "appwrite";

const RecordingScreen = ({ onNavigate, session }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [todayRecordingsCount, setTodayRecordingsCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const checkTodayRecording = useCallback(async () => {
    try {
      const session = await account.get();
      console.log('Current session:', session);
      if (session) {
        const today = new Date();
        const todayFormatted = format(today, "dd.MM.yyyy");
        console.log('Today\'s date:', todayFormatted);
        
        const files = await storage.listFiles('669aeb020037a5acddf2');
        console.log('All files:', files.files);
        
        const todayFiles = files.files.filter(file => {
          const [userId, ...rest] = file.name.split('_');
          const fileName = rest.join('_');
          console.log('Checking file:', file.name, 'userId:', userId, 'fileName:', fileName);
          return userId === session.$id && fileName.includes(todayFormatted);
        });
        
        console.log('Today\'s recordings found:', todayFiles);
        setTodayRecordingsCount(todayFiles.length);
      } else {
        console.log('No active session found');
        setTodayRecordingsCount(0);
      }
    } catch (error) {
      console.error('Error checking today\'s recordings:', error);
      setTodayRecordingsCount(0);
    }
  }, []);

  useEffect(() => {
    checkTodayRecording();
  }, [checkTodayRecording]);

  const showWarningModal = () => {
    setShowWarning(true);
  };

  useEffect(() => {
    const addListeners = () => {
      if (audioRef.current) {
        audioRef.current.addEventListener('timeupdate', updateTime);
        audioRef.current.addEventListener('loadedmetadata', () => {
          setDuration(audioRef.current.duration);
        });
      }
    };

    const removeListeners = () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', updateTime);
        audioRef.current.removeEventListener('loadedmetadata', () => {});
      }
    };

    addListeners();
    return removeListeners;
  }, [recordedAudio]);

  const updateTime = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const formatTime = (time) => {
    if (isNaN(time) || !isFinite(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const startRecordingHandler = () => {
    if (isRecording) {
      stopRecordingHandler();
      return;
    }
    if (todayRecordingsCount >= 2) {
      alert("You can only record two messages per day.");
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
  .then(stream => {
    let options = { mimeType: 'audio/webm;codecs=opus' };
    
    // Check if the browser supports WebM Opus recording
    if (!MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
      console.warn("audio/webm;codecs=opus is not supported, falling back to audio/wav");
      options = { mimeType: 'audio/wav' };
      
      // If audio/wav is also not supported, fall back to audio/mpeg
      if (!MediaRecorder.isTypeSupported('audio/wav')) {
        console.warn("audio/wav is not supported, falling back to audio/mpeg");
        options = { mimeType: 'audio/mpeg' };
      }
    }

    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();

    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", event => {
      audioChunks.push(event.data);
    });

    setIsRecording(true);

    mediaRecorderRef.current.audioChunks = audioChunks;
  })
  .catch(error => {
    console.error("Error accessing the microphone:", error);
  });
  };

      const stopRecordingHandler = () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.addEventListener("stop", () => {
          const audioBlob = new Blob(mediaRecorderRef.current.audioChunks, { type: mediaRecorderRef.current.mimeType });
          const audioUrl = URL.createObjectURL(audioBlob);
          setRecordedAudio(audioUrl);
          setIsRecording(false);
          setTimeout(() => {
            if (audioRef.current) {
              setDuration(audioRef.current.duration);
            }
          }, 100);
        });
      }
    };


  const togglePlayback = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleUpload = async () => {
    if (!recordedAudio) {
      console.error('No audio to upload');
      return;
    }

    if (todayRecordingsCount >= 2) {
      alert("You can only record two messages per day.");
      return;
    }

    await uploadAudio();
  };

  const uploadAudio = async () => {
    setIsUploading(true);

    try {
      const session = await account.get();
      if (!session) {
        throw new Error('No authenticated user found');
      }

      const userId = session.$id;

      const response = await fetch(recordedAudio);
      const blob = await response.blob();

      const currentDate = new Date();
      const formattedDate = format(currentDate, "dd.MM.yyyy_HH:mm");
      const fileExtension = mediaRecorderRef.current.mimeType.split(';')[0].split('/')[1];
      const filename = `${userId}_recording_${formattedDate}.${fileExtension}`;

      // Create a File object from the Blob
      const file = new File([blob], filename, { type: mediaRecorderRef.current.mimeType });
      console.log('File object created:', file instanceof File, file);

      const uploadedFile = await storage.createFile(
        '669aeb020037a5acddf2',  // bucket ID
        uuidv4(),  // Generate a unique file ID
        file,
        [
          Permission.read(Role.user(userId,["verified"]) ),
          Permission.update(Role.user(userId,["verified"]) ),
          Permission.delete(Role.user(userId,["verified"]) ),
          Permission.write(Role.user(userId,["verified"]) ),
          Permission.update(Role.any() )
        ]
      );

      if (!uploadedFile) {
        throw new Error('Failed to upload file');
      }

      // Log detailed file analysis
      console.log('File Analysis:', {
        General: {
          Format: mediaRecorderRef.current.mimeType.includes('webm') ? 'WebM' : (mediaRecorderRef.current.mimeType.includes('wav') ? 'WAVE' : 'MPEG Audio'),
          FileSize: '22.1 KiB',
          WritingApplication: 'Chrome',
          WritingLibrary: 'Chrome'
        },
        Audio: {
          Format: mediaRecorderRef.current.mimeType.includes('webm') ? 'Opus' : (mediaRecorderRef.current.mimeType.includes('wav') ? 'PCM' : 'MPEG Audio'),
          FormatVersion: mediaRecorderRef.current.mimeType.includes('webm') ? 'Opus' : (mediaRecorderRef.current.mimeType.includes('wav') ? '' : 'Version 1'),
          FormatProfile: mediaRecorderRef.current.mimeType.includes('webm') ? '' : (mediaRecorderRef.current.mimeType.includes('wav') ? '' : 'Layer 3'),
          Channels: '1 channel',
          SamplingRate: '48.0 kHz',
          CompressionMode: 'Lossy',
          StreamSize: '22.0 KiB (100%)'
        }
      });

      setShowSuccess(true);
      setTodayRecordingsCount(prevCount => prevCount + 1);
      setTimeout(() => {
        setShowSuccess(false);
        setRecordedAudio(null);
      }, 2000);
    } catch (error) {
      console.error('Error uploading file:', error.message);
      // You might want to add some user feedback here, like an error message
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = () => {
    setRecordedAudio(null);
  };

  return (
    <div className="flex flex-col min-h-screen p-4 sm:p-8" style={{ backgroundColor: colors.background }}>
      <div className="w-full max-w-4xl mx-auto flex flex-col h-full">
        <div className="w-full flex justify-between items-center mb-8 sm:mb-16">
          <h1 className="text-2xl sm:text-3xl font-bold" style={{ color: colors.text }}>
            Hey, welcome back!
          </h1>
          <button onClick={() => setIsNavOpen(true)} className="p-2 rounded-full" style={{ backgroundColor: colors.lightGrey }}>
            <Menu size={24} color={colors.text} />
          </button>
        </div>
        
        <div className="flex-grow"></div>
      </div>
      
      <div className="flex flex-col items-center mt-auto mb-8 sm:mb-16">
        {!recordedAudio ? (
          todayRecordingsCount >= 2 ? (
            <div className="text-center p-6 bg-opacity-20 rounded-lg" style={{ backgroundColor: colors.lightGrey }}>
              <h2 className="text-2xl sm:text-3xl font-bold mb-4" style={{ color: colors.text }}>
                Daily Limit Reached
              </h2>
              <p className="text-lg sm:text-xl mb-4" style={{ color: colors.text }}>
                You've already recorded two messages for today.
              </p>
              <p className="text-base sm:text-lg" style={{ color: colors.text }}>
                Come back tomorrow to share more thoughts!
              </p>
            </div>
          ) : (
            <>
              <p className="mb-6 text-lg sm:text-xl" style={{ color: colors.text }}>
                {isRecording ? 'Recording...' : `Ready to record (${todayRecordingsCount}/2 today)`}
              </p>
              <button
                onClick={startRecordingHandler}
                className="w-20 h-20 sm:w-24 sm:h-24 rounded-full flex items-center justify-center shadow-lg transition-transform hover:scale-105"
                style={{ backgroundColor: isRecording ? colors.lightGrey : colors.accent }}
              >
                <Mic size={40} color={isRecording ? colors.text : colors.background} />
              </button>
              <p className="text-sm sm:text-base mt-8 sm:mt-12 text-center" style={{ color: colors.text }}>
                Tap the microphone to {isRecording ? 'stop' : 'start'} recording
              </p>
            </>
          )
        ) : (
          <>
            <audio ref={audioRef} src={recordedAudio} />
            <div className="w-full max-w-md mb-4">
              <div className="text-center text-sm" style={{ color: colors.text }}>
                <span>{formatTime(currentTime)}/{formatTime(duration)}</span>
              </div>
            </div>
            <div className="flex space-x-4 mb-6">
              <button
                onClick={togglePlayback}
                className="p-3 rounded-full"
                style={{ 
                  backgroundColor: colors.accent,
                  opacity: isUploading ? 0.5 : 1,
                  cursor: isUploading ? 'not-allowed' : 'pointer'
                }}
                disabled={isUploading}
              >
                {isPlaying ? <Pause size={24} color={colors.background} /> : <Play size={24} color={colors.background} />}
              </button>
              <button
                onClick={handleUpload}
                className="p-3 rounded-full"
                style={{ 
                  backgroundColor: colors.lightGrey,
                  opacity: isUploading ? 0.5 : 1,
                  cursor: isUploading ? 'not-allowed' : 'pointer'
                }}
                disabled={isUploading}
              >
                <Upload size={24} color={colors.text} />
              </button>
              <button
                onClick={handleDelete}
                className="p-3 rounded-full"
                style={{ 
                  backgroundColor: colors.lightGrey,
                  opacity: isUploading ? 0.5 : 1,
                  cursor: isUploading ? 'not-allowed' : 'pointer'
                }}
                disabled={isUploading}
              >
                <Trash2 size={24} color={colors.text} />
              </button>
            </div>
          </>
        )}
      </div>
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-full p-4">
            <CheckCircle size={48} color={colors.accent} />
          </div>
        </div>
      )}
      <Navigation 
        isOpen={isNavOpen} 
        onClose={() => setIsNavOpen(false)} 
        onNavigate={onNavigate}
        currentScreen="recording"
      />
    </div>
  );
};

export default RecordingScreen;
