import React, { useState } from 'react';
import { Menu } from 'lucide-react';
import { colors } from '../utils/colors';
import Navigation from './Navigation';

const AccountManagement = ({ onNavigate, setSession }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  // Mock data for the account
  const [account, setAccount] = useState({
    email: 'john.doe@example.com'
  });

  const handleDeleteAccount = () => {
    // In a real application, this would call an API to delete the account
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      alert('Account deleted successfully');
      // Here you would typically log the user out and redirect them
      onNavigate('dashboard');
    }
  };

  return (
    <div className="flex flex-col min-h-screen p-4 sm:p-8" style={{ backgroundColor: colors.background }}>
      <div className="w-full max-w-4xl mx-auto flex flex-col h-full">
        <div className="w-full flex justify-between items-center mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold" style={{ color: colors.text }}>Account Management</h1>
          <button onClick={() => setIsNavOpen(true)} className="p-2 rounded-full" style={{ backgroundColor: colors.lightGrey }}>
            <Menu size={24} color={colors.text} />
          </button>
        </div>
        
        <div className="bg-white shadow-md rounded px-4 sm:px-8 py-6 mb-4">
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              value={account.email}
              readOnly
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
              type="button"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
      <Navigation 
        isOpen={isNavOpen} 
        onClose={() => setIsNavOpen(false)} 
        onNavigate={onNavigate}
        currentScreen="account"
        setSession={setSession}
      />
    </div>
  );
};

export default AccountManagement;
