import React, { useState, useEffect } from 'react';
import { account, ID, storage, databases } from '../utils/appwriteClient';

const Auth = ({ setSession }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    const secret = urlParams.get('secret');

    if (userId && secret) {
      handleMagicURLLogin(userId, secret);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const token = await account.createMagicURLToken(ID.unique(), email,window.location.origin);
      console.log('Magic URL token created successfully:', token);
      setMessage('Check your email for the login link!');
    } catch (error) {
      console.error('Error creating magic URL token:', error);
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleMagicURLLogin = async (userId, secret) => {
    setLoading(true);
    try {
      const session = await account.createSession(userId, secret);
      
      setSession(session);
      
      // Redirect to the origin after successful login
      window.location.href = window.location.origin;
    } catch (error) {
      console.error('Error creating session:', error);
      setMessage('Failed to log in. Please try again.');
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Sign In / Sign Up</h1>
        <input
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? 'Loading...' : 'Send magic link'}
        </button>
        {message && <p className="mt-4 text-sm text-center text-blue-500">{message}</p>}
      </form>
    </div>
  );
};

export default Auth;
