import React, { useState, useEffect, useCallback } from 'react';
import RecordingScreen from './components/RecordingScreen';
import DashboardScreen from './components/DashboardScreen';
import AccountManagement from './components/AccountManagement';
import Auth from './components/Auth';
import { account } from './utils/appwriteClient';

const App = () => {
  const [currentScreen, setCurrentScreen] = useState('auth');
  const [session, setSession] = useState(null);
  const [isLocalhost, setIsLocalhost] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkSession = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = await account.get();
      setSession(user);
      if (currentScreen === 'auth') {
        setCurrentScreen('dashboard');
      }
      console.log('Current logged-in user:', { id: user.$id, ...user });
    } catch (error) {
      setSession(null);
      setCurrentScreen('auth');
      console.log('No user logged in');
    } finally {
      setIsLoading(false);
    }
  }, [currentScreen]);

  useEffect(() => {
    checkSession();
    setIsLocalhost(window.location.hostname === 'localhost');
  }, [checkSession]);

  useEffect(() => {
    if (currentScreen === 'auth' && session) {
      setCurrentScreen('recording');
    }
  }, [currentScreen, session]);

  const handleNavigation = (screen) => {
    console.log('Navigating to:', screen);
    setCurrentScreen(screen);
    checkSession();
  };

  const handleDevLogin = async () => {
    try {
      await account.createEmailSession('dev@example.com', 'devpassword123');
      await checkSession();
      setCurrentScreen('recording');
    } catch (error) {
      console.error('Dev login error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await account.deleteSession('current');
      await checkSession();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderScreen = () => {
    if (isLoading) {
      return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (!session) {
      return (
        <div>
          <Auth setSession={setSession} />
          {isLocalhost && (
            <button onClick={handleDevLogin} className="dev-login-button">
              Developer Login
            </button>
          )}
        </div>
      );
    }

    const commonProps = {
      onNavigate: handleNavigation,
      session: session,
      onLogout: handleLogout,
      currentScreen: currentScreen
    };

    switch (currentScreen) {
      case 'recording':
        return <RecordingScreen {...commonProps} />;
      case 'dashboard':
        return <DashboardScreen {...commonProps} />;
      case 'account':
        return <AccountManagement {...commonProps} />;
      default:
        console.warn(`Unknown screen: ${currentScreen}`);
        return null;
    }
  };

  return (
    <div className="h-screen">
      {renderScreen()}
    </div>
  );
};

export default App;
