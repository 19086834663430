import React, { useEffect, useState } from 'react';
import { X, LogOut } from 'lucide-react';
import { colors } from '../utils/colors';
import { account } from '../utils/appwriteClient';


const Navigation = ({ isOpen, onClose, onNavigate, currentScreen, setSession }) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsRendered(true);
    } else {
      const timer = setTimeout(() => setIsRendered(false), 300); // Match this with the CSS transition time
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isRendered) return null;

  const handleLogout = async () => {
    try {
      await account.deleteSession('current');
      setSession(null);
      onNavigate('auth');
    } catch (error) {
      console.error('Error logging out:', error);
    }
    onClose();
  };

  const navItems = [
    { name: 'Record', route: 'recording' },
    { name: 'Dashboard', route: 'dashboard' },
    { name: 'Account', route: 'account' },
  ];

  return (
    <div className={`fixed inset-0 bg-black transition-opacity duration-300 z-50 flex justify-end ${isOpen ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'}`}>
      <div className={`w-64 bg-white h-full shadow-lg p-4 flex flex-col transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex justify-end">
          <button onClick={onClose} className="p-2 rounded-full hover:bg-gray-200">
            <X size={24} color={colors.text} />
          </button>
        </div>
        <nav className="mt-8">
          <ul>
            {navItems.map((item) => (
              <li key={item.route} className="mb-4">
                <button
                  onClick={() => {
                    onNavigate(item.route);
                    onClose();
                  }}
                  className={`w-full text-left py-2 px-4 rounded transition-colors ${
                    currentScreen === item.route
                      ? 'bg-blue-100 text-blue-600'
                      : 'hover:bg-gray-200'
                  }`}
                  style={{ color: currentScreen === item.route ? colors.accent : colors.text }}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="mt-auto">
          <button
            onClick={handleLogout}
            className="w-full text-left py-2 px-4 rounded transition-colors hover:bg-gray-200 flex items-center"
            style={{ color: colors.text }}
          >
            <LogOut size={18} className="mr-2" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
